body {
    margin: 0;
}

@font-face {
    font-family: Noto Sans;
    src: url("../../public/NotoSans.ttf");
    font-style: normal;
    font-weight: 1 1000;
}

:root {
    --width-desktop: 414px;
    --width-mobile: 414px;
    --backgroundColor-one: #FFFFFF;
    --backgroundColor-second: #F6F6F8;
    --backgroundColor-third: #EBECEE;
    --backgroundColor-ripple: #f2f2f2;
    --backgroundColor-grey: #b0b0b0;
    --backgroundColor-button-registration: #1976d2;
    --backgroundColor-error: #FDEDEB;
    --backgroundColor-loading-end: #FDFDFD;
    --gradient-color-start: #0161A4;
    --text-color-link: #00BEB4;
    --text-color-error: #E74C3C;
    --text-color-more: #D02B21;
    --text-color-medium: #E7B10A;
    --text-color-pending: #F39C12;
    --text-color-dark: #000000;
    --text-color-available: #06205E;
    --text-color-resend: #00BEB4;
    --text-one: #181A21;
    --text-grey: #848790;
    --text-grey-second: #BFC1C5;
    --text-light-grey-third: #849AA8;

    --toastify-color-light: #FFFFFF;
    --toastify-color-dark: #181A21;

    --toastify-text-color-light: #181A21;
    --toastify-text-color-dark: #FFFFFF;

    --bottom-navigation-background: 255;

    --shadow-card-container-first: 24;
    --shadow-card-container-second: 26;
    --shadow-card-container-third: 33;
}

.Toastify__close-button {
    display: none;
}

.Toastify__toast {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    width: auto;
    max-width: 260px;
    height: 60px;
    border-radius: 12px;
    min-height: 40px;
    max-height: 40px;
    padding: 8px 16px;
}

:root {
    --font-family: "Noto Sans", sans-serif;
}

[data-theme='dark'] {
    --backgroundColor-one: #141414;
    --backgroundColor-second: #1F1F1F;
    --backgroundColor-third: #303030;
    --backgroundColor-error: #332324;
    --text-one: #FEFEFE;
    --text-grey: #9C9C9D;
    --backgroundColor-loading-end: #020202;

    --bottom-navigation-background: 0;

    --shadow-card-container-first: 231;
    --shadow-card-container-second: 229;
    --shadow-card-container-third: 222;
}

#root {
    overflow-x: hidden;
    width: 100%;
    position: absolute;
    height: 100%;
    min-height: 100%;
    background-color: var(--backgroundColor-one);
}

a {
    color: var(--text-color-link) !important;
}

.wrapper {
    text-align: center;
}

.vector-position {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: -75px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:valid,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    color: var(--text-one) !important;
    font-family: Noto Sans, serif !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding-bottom: 2px !important;
    -webkit-text-fill-color: var(--text-one) !important;
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.walletLayout {
    width: 100%;
    gap: 16px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
}

.mainNavigationContainer {
    max-width: 414px;
    width: 100%;
    box-sizing: border-box;
    gap: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.hideBalanceContainer {
    max-width: 414px;
    padding: 0 24px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
}

.innerAssetsContainer {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.innerAssetsHeaderText {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: var(--text-grey);
    width: 100%;
}

.innerAssetsTransferContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-direction: row;
}

.innerAssetsTransferText {
    margin: 0;
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color-link);
}

.forgotPasswordContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.assetsWalletSearchContainer {
    position: relative;
    max-width: 414px;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
}

.assetsContainer {
    max-width: 414px;
    padding: 0 24px;
    height: 100%;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
}

.assetsNavigationContainer {
    max-width: 414px;
    width: 100%;
    box-sizing: border-box;
}

.exchangeInputOuterContainer {
    height: 110px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
}

.exchangeAvailableContainerLoading {
    width: 59px;
    height: 12px;
    background-color: var(--backgroundColor-third);
    border-radius: 2px;
}

.exchangeRateContainerLoading {
    width: 89px;
    height: 12px;
    background-color: var(--backgroundColor-third);
    border-radius: 2px;
}

.exchangeInputContainer {
    height: 110px;
    padding: 16px 24px;
    background: var(--backgroundColor-second);
    border-radius: 24px;
    width: 100%;
    display: flex;
    border: 1px solid transparent;
    flex-direction: column;
    box-sizing: border-box;
}

.baseButtonContainer {
    width: 100%;
}

.exchangeSwapContainer {
    position: relative;
    margin: -30px 0;
}


.exchangeSwapIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0;
}

.upperAssetListContainer {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}

.searchAssetsContainer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px;
}

.searchAssetOuterBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.searchAssetBlockHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
    width: 100%;
    text-align: left;
}

.searchAssetOuterCommonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.searchAssetsBlock {
    width: 100%;
    padding: 0px 24px;
    box-sizing: border-box;
    border-radius: 12px;
    gap: 8px;
    background-color: var(--backgroundColor-one);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.searchAssetsItem {
    width: 100%;
    height: 72px;
    padding: 14px 0px;
    box-sizing: border-box;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.searchAssetTextContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    z-index: 1;
    position: absolute;
    margin-left: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
}

.searchAssetTextHeaderLoading {
    width: 204px;
    height: 16px;
    border-radius: 12px;
    animation: loading 1s infinite;
}

.searchAssetTextBottomLoading {
    border-radius: 12px;
    animation: loading 1s infinite;
    width: 127px;
    height: 16px;
}

.marketAssetsContainer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px;
}

.historyOperationsContainer {
    overflow-y: scroll;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.savedAddressesContainer {
    overflow-y: scroll;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.invoiceHistoryContainer {
    gap: 16px;
    overflow-y: scroll;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.biometryBottomContainer {
    box-sizing: border-box;
    padding: 0 24px;
    position: absolute;
    bottom: 0;
    gap: 16px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.authBottomButtonContainer {
    box-sizing: border-box;
    padding: 0 24px;
    position: absolute;
    bottom: 0;
    gap: 16px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.forgotPasswordText {
    text-align: left;
    color: var(--text-color-link);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    margin: 0;
}

.input {
    width: 100%;
    height: 60px;
}

.MuiFormLabel-asterisk {
    display: none;
}

.loginErrorText {
    color: var(--text-color-error);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    width: 100%;
    margin: 0;
}

.backButton {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
}

.authMainContainer {
    margin-bottom: 16px;
    margin-top: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
}

.noMargin {
    margin: 0 !important;
}

.modalOtpComponent {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--backgroundColor-one);
    border-radius: 12px;
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06), 0px 0px 24px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.04);
}

.registrationInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    flex-direction: row;
}

.countrySelectInput {
    width: 109px;
    height: 60px;
}

.countryCodeImg {
    width: 24px;
    height: 24px;
}

.modalComponent {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--backgroundColor-one);
    border-radius: 12px;
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06), 0px 0px 24px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.04);
}

.aboutComponent {
    background-color: var(--backgroundColor-second);
}

.innerContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
}

.searchComponent {
    height: auto !important;
    min-height: 100%;
    background-color: var(--backgroundColor-second);
}

@media (width <=480px) {
    .Toastify__toast {
        margin-top: 16px;
    }

    .innerComponent {
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .baseButtonContainer {
        width: 100%;
    }

    .modalOtpComponent {
        width: calc(100% - 96px);
    }

    .modalComponent {
        width: calc(100% - 96px);
        height: 319px;
    }


}

@media (width > 480px) {
    .innerComponent {
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .walletLayout {
        width: 414px;
    }

    .modalOtpComponent {
        width: calc(var(--width-desktop) - 48px);
    }

    .modalComponent {
        width: var(--width-mobile);
        height: 433px;
    }
}

.modalInput {
    margin-bottom: 16px;
    position: sticky;
    z-index: 1;
}

.modalItem {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modalOtpItemCode {
    margin: 0;
    color: var(--text-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.modalItemLogoTextWithoutMargin {
    color: var(--text-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 6px 0 0 0;
}

.modalItemLogoText {
    color: var(--text-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 6px 0 0 24px;
}

.modalItemCode {
    color: var(--text-grey);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.waveIconGifContainer {
    max-width: 414px;
    width: 100%;
    box-sizing: border-box;
    height: 78px;
}

.modalItemImg {
    width: 36px;
    height: 36px;
}

.button {
    width: 100%;
}

.welcomeText {
    width: 100%;
    margin: 0;
    color: var(--text-one);
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;

}

.pin_input {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex: 1;
}

.pin_input__field {
    width: 51px;
    height: 57px;
    background: var(--backgroundColor-second);
    border-radius: 12px;
    color: var(--text-one);
    border: 1px solid var(--backgroundColor-second);
    padding: 0;
    font-size: 16px !important;
    text-align: center;
}

.pin_input__field-error {
    background: var(--backgroundColor-error);
    border: 1px solid var(--backgroundColor-error);
}

.pin_input__field-loading-child {
    animation: fade 1s infinite;
}

@keyframes fade {

    0%,
    100% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }
}

.pin_input__field-loading-child:nth-child(1) {
    animation-delay: 0s;
}

.pin_input__field-loading-child:nth-child(2) {
    animation-delay: 0.3s;
}

.pin_input__field-loading-child:nth-child(3) {
    animation-delay: 0.6s;
}

.pin_input__field-loading-child:nth-child(4) {
    animation-delay: 0.9s;
}

.pin_input__field-loading-child:nth-child(5) {
    animation-delay: 1.2s;
}

.pin_input__field-loading-child:nth-child(6) {
    animation-delay: 1.5s;
}

.pin_input__field:focus-visible {
    outline: 2px solid var(--text-color-link);
}

.confirmEmailText {
    width: 100%;
    margin: 0;
    color: var(--text-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
}

.confirmCodeText {
    width: 100%;
    margin: 0;
    color: var(--text-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.codeInputBlock {
    width: calc(100% - 4px);
    display: flex;
    flex-direction: column;
    height: 62px;
}

.resendCodeText {
    width: 100%;
    color: var(--text-color-resend);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    align-self: flex-start;
    text-align: left;
}

.resendCodeText-inactive {
    text-align: left;
    width: 100%;
    color: var(--text-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    align-self: flex-start;
}

.modalRegistrationItem {
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.modalRegistrationItemLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 56px;
}

.commonDeviceContainer {
    width: 100%;
    background-color: var(--backgroundColor-one);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    gap: 16px;
    border-radius: 12px;
    box-sizing: border-box;
}

.commonContainer {
    width: 100%;
    background-color: var(--backgroundColor-one);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0px;
    gap: 8px;
    border-radius: 12px;
    box-sizing: border-box;
}

.commonSavedAddressesContainer {
    width: 100%;
    background-color: var(--backgroundColor-one);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    border-radius: 12px;
    box-sizing: border-box;
}

.savedAddressContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 8px;
}

.savedAddressContainerHeaderLoading {
    gap: 8px;
    height: 16px;
    width: 130px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.saveAddressTabsContainer {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 42px;
    border-radius: 12px;
    padding: 2px;
    background-color: var(--backgroundColor-second);
}

.saveAddressTabContainer {
    width: 121px;
    height: 38px;
    border-radius: 5px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.saveAddressTabContainerActive {
    background-color: var(--backgroundColor-one);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
    border-radius: 10px !important;
}

.saveAddressTabTextInactive {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--text-grey);
    margin: 0;
}

.saveAddressTabTextActive {
    color: var(--text-one) !important;
}

.commonContainerLoading {
    height: 184px;
    width: 366px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.smallerCommonContainerLoading {
    height: 92px;
    width: 366px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.savedAddressContainerHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 20px;
    margin: 0;
}

.savedAddressContainerHeaderText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
    text-align: left;
}



.commonGreyContainer {
    width: 100%;
    background-color: var(--backgroundColor-second);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0px;
    gap: 8px;
    border-radius: 12px;
    box-sizing: border-box;
}

.commonItem {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 56px;
}

.commonDeviceItem {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 24px;
}

.commonText {
    width: 100%;
    text-align: left;
    margin: 0 0 0 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
}

.innerCommonItem {
    display: flex;
    align-items: center;
    flex-direction: row;

}

.innerCommonText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
}

.modalLocaleContent {
    display: flex;
    flex-direction: column;
}

.todayText {
    width: 48px;
    height: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--text-one);
    margin: 0;
}

.modalOperationDetailsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 16px;
}

.modalComponentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.modalComponentHeaderEndPlacement {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.depositCryptoBottomContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
}

.depositCryptoWarningText {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: var(--text-grey);
    margin: 0;
    text-align: left;
}

.depositCryptoWarningTextNetwork {
    margin: 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: var(--text-one);
}

.depositCryptoBottomAddressContainer {
    width: 100%;
    height: 132px;
    padding: 8px 24px 16px 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--text-one);
    border-radius: 12px;
}

.depositCryptoBottomAddressContainerText {
    word-wrap: break-word;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin: 0;
    text-align: left;
    width: 100%;
    color: var(--backgroundColor-one);
}

.copyImageContainer {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
}

.depositCryptoBottomAddressInnerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.depositCryptoBottomAddressInnerContainerText {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    color: var(--backgroundColor-one);
    opacity: 0.7;
}

.countryImage {
    width: 44px;
    height: 44px;
}

.modalLocaleText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0 0 0 8px;
}

.modalLocaleItem {
    height: calc(72px - 24px);
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
}

.closeButton {
    width: 24px;
    height: 24px;
    color: var(--text-light-one);
}

.modalWalletConvertSummaryHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: var(--text-one);
    margin: 0;
    text-align: center;
}

.whaleConvertIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.walletConfirmIdentityContainer {
    width: 286px;
    gap: 16px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
}

.walletConfirmIdentityWhale {
    width: 140px;
    height: 140px;
}

.walletConfirmIdentityHeader {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--text-one);
}

.walletConfirmIdentityBottom {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--text-grey);
}

.walletConfirmIdentityButton {
    width: 100%;
    height: 56px;
}

.modalWalletConvertSummaryBottom {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--text-grey);
    margin: 0;
}

.closeFullWidthButton {
    width: 100%;
    height: 56px;
}

.passwordInput input[type='password']::-ms-reveal,
.passwordInput input[type='password']::-ms-clear {
    display: none;
}

.mobileInputBlock {
    display: flex;
    flex-direction: row;
    align-content: space-between;
}

.outerAboutHeaderLayout {
    z-index: 2;
    position: fixed;
    background-color: var(--backgroundColor-second);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
}

.outerHeaderLayout {
    z-index: 2;
    position: fixed;
    background-color: var(--backgroundColor-one);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
}

.aboutHeaderLayout {
    margin-top: 8px;
    width: 100%;
    max-width: 414px;
    box-sizing: border-box;
    position: fixed;
    background-color: var(--backgroundColor-second);
    height: 60px;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
}

.headerLayout {
    margin-top: 8px;
    width: 100%;
    max-width: 414px;
    box-sizing: border-box;
    position: fixed;
    background-color: var(--backgroundColor-one);
    height: 60px;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
}

.headerAccountContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    max-width: 52px;
    width: 100%;
}

.headerNotificationContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    max-width: 52px;
    width: 100%;
}

.headerAccountInnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--text-color-link);
    border-radius: 50%;
}

.headerNotificationInnerContainer {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--backgroundColor-second);
    border-radius: 50%;
}

.headerSearchInnerText {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-grey-second);
}

.headerSearchContainer {
    width: 100%;
    height: 42px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.headerSearchInnerContainer {
    background-color: var(--backgroundColor-second);
    border-radius: 99px;
    width: 100%;
    height: 42px;
    gap: 10px;
    box-sizing: border-box;
    padding-left: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.headerAccountInnerText {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: var(--backgroundColor-one);
    margin: 0;
}

.innerBottomNavigationItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 138px;
    height: 64px;
}

.innerBottomNavigationText {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: var(--text-grey);
}

.depositCryptoWarningTextLoading {
    width: 288px;
    height: 24px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.depositCryptoBottomAddressContainerLoading {
    width: 100%;
    height: 132px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.mainBalanceContainerLoading {
    width: 180px;
    height: 42px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.secondaryBalanceContainerLoading {
    width: 120px;
    height: 16px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.innerHideBalanceContainerLoading {
    width: 120px;
    height: 16px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.confirmEmailTextLoading {
    width: 100px;
    height: 36px;
    animation: loading 1s infinite;
    border-radius: 12px;
    margin: 0
}

.navIconLoading {
    height: 56px;
    width: 56px;
    animation: loading 1s infinite;
    border-radius: 50%;
}

.scrolledAssetsNavigationLoading {
    width: 100%;
    height: 32px;
    border-radius: 12px;
    animation: loading 1s infinite;
}

.upperMarketItemTextLoading {
    margin: 0;
    width: 60px;
    height: 16px;
    border-radius: 12px;
    animation: loading 1s infinite;
}

.bottomMarketItemTextLoading {
    margin: 0;
    width: 161px;
    height: 16px;
    border-radius: 12px;
    animation: loading 1s infinite;
}

.favouriteIconLoading {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    animation: loading 1s infinite;
}

.assetsDateFilterContainerLoading {
    width: 74px;
    height: 32px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.assetIconLoading {
    min-width: 44px;
    width: 44px;
    height: 44px;
    animation: loading 1s infinite;
    border-radius: 50%;
}

.innerCommonTextLoading {
    height: 20px;
    width: 80px;
    animation: loading 1s infinite;
    border-radius: 12px;
    margin: 0;
}

.commonTextLoading {
    margin: 0;
    height: 20px;
    width: 80px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.assetIconBigLoading {
    min-width: 72px;
    width: 72px;
    height: 72px;
    animation: loading 1s infinite;
    border-radius: 50%;
}

.innerAssetHeaderTextLoading {
    width: 204px;
    height: 16px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.searchIconContainerLoading {
    width: 32px;
    height: 16px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.innerAssetBottomTextLoading {
    border-radius: 12px;
    margin-top: 8px;
    width: 127px;
    height: 16px;
    animation: loading 1s infinite;
}

@keyframes loading {

    0%,
    100% {
        background-color: var(--backgroundColor-second);
    }

    50% {
        background-color: var(--backgroundColor-loading-end);
    }
}

.innerBottomNavigationTextActive {
    color: var(--text-one);
}

.endAlignment {
    justify-content: flex-end;
}

.f2xIcon {
    width: 28px;
    height: 28px;
    margin-right: 8px;
}

.headerLogoContainer {
    margin: 0 16px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerLogoContainerLoading {
    margin: 0 16px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 180px;
    height: 36px;
    border-radius: 12px;
    animation: loading 1s infinite;
    box-sizing: border-box;
}

.themeHeaderTextContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    flex-direction: column;
}

.themeHeaderMainText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0;
    width: 100%;
    text-align: left;
}

.themeHeaderBottomText {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--text-grey);
    width: 100%;
    margin: 0;
    text-align: left;
}

.switchContainer {
    width: 45px;
    height: 28px;
    border-radius: 200px;
    background-color: var(--text-grey-second);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.switchContainerActive {
    background-color: var(--text-color-link);
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
}

.innerContainerEllipseActive {
    transform: translateX(16px);
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
}

.modalThemeInnerSelectContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.modalThemeInnerSelectText {
    text-align: center;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: var(--text-one);
}

.innerContainerEllipse {
    background-color: var(--backgroundColor-one);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 4px;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.accountUserIdMainLoading {
    width: 316px;
    box-sizing: border-box;
    height: 32px;
    border-radius: 12px;
    animation: loading 1s infinite;
}

.topPlacement {
    position: relative;
    height: auto;
}

.twoFAButton {
    width: 100%;
    position: relative;
    display: flex;
    border-radius: 12px;
    border: none;
    padding: 8px 0px;
    height: 72px;
    background: var(--backgroundColor-one);
    justify-content: center;
    flex-direction: row;
    align-items: center;
    transition: 300ms;
}

.twoFAButton:hover {
    background: var(--backgroundColor-third);
    opacity: 0.90;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}

.twoFAButton:active {
    background: var(--backgroundColor-second);
    opacity: 0.8;
}

.twoFAButtonContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    height: 56px;
}

.twoFAButtonContentIconText {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.twoFAButtonImageContainer {
    width: 24px;
    height: 24px;
}

.twoFAButtonText {
    margin: 0 0 0 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-one);
}

.headerMainText {
    margin: 0;
    color: var(--text-one);
    text-align: left;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
}

.innerContainerMainText {
    margin: 0;
    color: var(--text-one);
    text-align: left;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    width: 100%;
}

.additionalTwoFaText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
    text-align: left;
    width: 100%;
}

.headerNavContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerNavIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
}

.estimatedBalanceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.estimatedBalanceText {
    margin: 0;
    color: var(--text-grey);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.estimatedBalanceIcon {
    margin-left: 8px;
    width: 16px;
    height: 16px;
}

.balanceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}

.mainBalanceContainer {
    display: flex;
    flex-direction: row;
}

.secondaryBalanceContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mainBalanceText {
    margin: 0;
    color: var(--text-one);
    text-align: center;
    font-size: 40px;
    font-weight: 800;
    line-height: 56px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.mainBalanceDecimalText {
    margin: 0;
    color: var(--text-one);
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
}

.mainBalanceSelectContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
    margin-left: 8px;
}

.currencyText {
    margin: 0 0 4px 0;
    color: var(--text-one);
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
}

.totalAmountTextChev {
    margin-bottom: 8px;
}

.currencyTextChev {
    width: 24px;
    height: 24px;
}

.secondaryBalanceText {
    margin: 0;
    color: var(--text-grey);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.secondaryBalanceAnalyticsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-left: 8px;
}

.analyticsChev {
    width: 16px;
    height: 16px;
}

.secondaryBalanceAnalyticsText {
    margin: 0;
    color: var(--text-grey);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: right;
}

.secondaryNegativeBalanceAnalyticsText {
    margin: 0;
    color: var(--text-grey);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: right;
}

.navigationItem {
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.navigationIcon {
    width: 56px;
    height: 56px;
}

.invoiceHistoryIcon {
    width: 24px;
    height: 24px;
}

.navigationText {
    margin: 0;
    color: var(--text-one);
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.bottomNavigationContainer {
    height: 64px;
    width: 100%;
    position: fixed;
    z-index: 999;
    bottom: 0;
    background: rgba(var(--bottom-navigation-background), var(--bottom-navigation-background), var(--bottom-navigation-background), 0.8);
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.innerBottomNavigationContainer {
    display: flex;
    gap: 0px;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: var(--width-desktop);
    height: 64px;
}

.bottomNavigationItem {
    width: 139px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bottomNavigationIcon {
    width: 32px;
    height: 32px;
}

.bottomNavigationText-active {
    color: var(--text-one) !important
}

.bottomNavigationText {
    margin: 0;
    color: var(--text-grey);
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
}

.innerHideBalanceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.searchIconContainer {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hideBalanceText {
    margin: 0;
    color: var(--text-grey);
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.secretKeyContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
    padding: 16px 24px;
    background: var(--backgroundColor-second);
    border-radius: 12px;
}

.modalSearchInput {
    width: 297px;
    position: sticky;
    height: 42px;
    z-index: 1;
}

.modalLogoutContent {
    gap: 16px;
}

.modalWalletSearchContent {
    overflow-y: scroll;
}

.modalGenerateAccountStatementContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
}

.modalGenerateAccountStatementUnverifiedTextHeader {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    color: var(--text-one);
    text-align: center;
}

.rejectOperationModalSubtitleText {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    margin: 0;
    color: var(--text-one);
    text-align: left;
}

.modalGenerateAccountStatementUnverifiedTextBottom {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--text-grey)
}

.walletSearchModalNotFoundText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
}

.modalWalletSearchHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 16px;
}

.modalWalletSearchComponent {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--backgroundColor-one);
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06), 0px 0px 24px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.04);
    gap: 16px;
    box-sizing: border-box;
    max-width: 414px;
    height: 100%;
}

.walletSearchModalText {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-one);
}

.userInfoInputField {
    width: 100%;
    box-sizing: border-box;
    height: 60px;
}

.userInfoFieldIcon {
    height: 16px;
    width: 16px;
    margin-right: 4px;
    margin-top: 14px;
}

.verificationRequiredHeaderText {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: var(--text-one);
    margin: 0;
    text-align: center;
    box-sizing: border-box;
}

.statementModalGreyContainer {
    background-color: var(--backgroundColor-second);
}

.verificationRequiredBottomText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--text-grey);
    margin: 0;
    box-sizing: border-box;
}

.headerAccountInnerContainerText {
    margin: 0;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: var(--backgroundColor-one);
}

.accountUserIdText {
    box-sizing: border-box;
    padding: 0 24px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    color: var(--text-grey);
    text-align: left;
}

.accountUserIdMainText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin: 0;
    text-align: left;
    color: var(--text-one);
}

.convertButton {
    height: 56px;
    width: 160px;
}

.transferButton {
    width: 100%;
    height: 56px;
}

.modalWalletConvertSelectAllContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 16px;
}

.modalWalletConvertSelectAllText {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
    text-align: left;
}

.marketAssetsEmptyFavouriteContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    margin-top: 48px;
    width: 280px;
    height: auto;
}

.marketAssetsEmptyFavouriteHeader {
    margin: 0;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: var(--text-one);
}

.marketAssetsEmptyFavouriteBottom {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--text-grey);
}


.modalWalletConvertComponent {
    gap: 16px;
    box-sizing: border-box;
    max-width: 414px;
    width: 100%;
    height: auto;
    max-height: 608px;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--backgroundColor-one);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06), 0px 0px 24px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.04);
}

.modalMarketAssetDetailsHeaderText {
    right: 0;
    left: 0;
    position: absolute;
    z-index: 2;
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
    margin: 0 auto;
}

.marketAssetDetailsMainIcon {
    width: 72px;
    height: 72px;
}

.marketAssetDetailsHistoryContainer {
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    height: auto;
}

.marketAssetDetailsHistoryInnerContainer {
    width: 100%;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: scroll;
}

.marketAssetDetailsHistoryInnerItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
}

.marketAssetDetailsHistoryInnerContainerInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 4px;
}

.marketAssetDetailsHistoryInnerNavigationContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.marketAssetDetailsHistoryContainerHeader {
    text-align: left;
    width: 100%;
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
}

.marketAssetDetailsHistoryInnerNavigationContainerItemLoading {
    width: 276px;
    height: 32px;
    border-radius: 12px;
    animation: loading 1s infinite;
}

.marketAssetDetailsHistoryNotFoundHeader {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
}

.marketAssetDetailsHistoryInnerContainerInfoHeaderLoading {
    width: 60px;
    height: 16px;
    border-radius: 12px;
    animation: loading 1s infinite;
}

.marketAssetDetailsHistoryInnerContainerInfoBottomLoading {
    width: 161px;
    height: 16px;
    border-radius: 12px;
    animation: loading 1s infinite;
}

.marketAssetDetailsNavigationContainer {
    box-sizing: border-box;
    padding: 0 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-direction: row;
}

.marketAssetDetailsNavigationContainerLoading {
    padding: 0 24px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-direction: row;
}

.marketAssetDetailsNavigationContainerItemLoading {
    width: 112px;
    height: 42px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.assetDetailsTotalSplitContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 12px;
    gap: 16px;
    background-color: var(--backgroundColor-second);
    box-sizing: border-box;
    padding: 24px;
}

.assetDetailsTotalBalanceOuterContainer {
    padding: 0 24px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.assetDetailTotalSplitItem {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.assetDetailsTotalSplitItemDivider {
    width: 100%;
    height: 1px;
    background-color: var(--backgroundColor-third);
}

.assetDetailTotalSplitItemHeader {
    text-align: left;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
}

.assetDetailTotalSplitItemAmount {
    text-align: left;
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
}

.marketAssetDetailsNavigationContainerItem {
    width: 100%;
    height: 42px;
}

.assetDetailsTotalBalanceContainerLoading {
    width: 180px;
    height: 42px;
    animation: loading 1s infinite;
    border-radius: 12px;
}

.assetDetailsTotalBalanceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
}

.assetDetailsTotalBalanceHeader {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
}

.assetDetailsTotalBalanceMain {
    gap: 4px;
    margin: 0;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    color: var(--text-one);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.assetDetailsTotalBalanceMainCode {
    margin: 0;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    color: var(--text-grey);
}

.assetDetailsTotalBalanceBottom {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
}

.backIconContainer {
    z-index: 3;
    left: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    width: 48px;
}

.assetDetailsHeaderIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
}

.modalMarketAssetDetailsRightContainer {
    z-index: 3;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}

.headerAssetDetailsLayout {
    z-index: 3;
    padding-top: 8px;
    position: fixed;
    background-color: var(--backgroundColor-one);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
}

.headerGreyLayout {
    background-color: var(--backgroundColor-second);
}

.headerHistorySearchLayout {
    background-color: var(--backgroundColor-one) !important;
}

.headerSearchLayout {
    padding-top: 8px;
    position: fixed;
    background-color: var(--backgroundColor-second);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 60px;
    z-index: 3;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
}

.invoiceHistoryContainerLoading {
    width: 100%;
    border-radius: 12px;
    background-color: var(--backgroundColor-one);
    box-sizing: border-box;
    height: 72px;
    animation: loading 1s infinite;
}

.innerHeaderAssetDetailsLayout {
    max-width: 414px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    background-color: var(--backgroundColor-one);
    height: 60px;
    padding: 0 24px;
    isolation: isolate;
    gap: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
}

.innerRejectHeaderLayout {
    max-width: 414px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    background-color: var(--backgroundColor-one);
    height: 60px;
    padding: 0 24px;
    isolation: isolate;
    gap: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    z-index: 3;
}

.cancelOperationHeaderText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color-link);
    margin: 0;
    text-align: center;
}

.innerHeaderGreyLayout {
    background-color: var(--backgroundColor-second);
}

.innerHeaderHistorySearchLayout {
    background-color: var(--backgroundColor-one) !important;
}

.innerHeaderSearchLayout {
    max-width: 414px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    background-color: var(--backgroundColor-second);
    height: 60px;
    padding: 0 24px;
    isolation: isolate;
    gap: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
}

.modalMarketTimeFrameComponent {
    width: 100%;
    gap: 16px;
    box-sizing: border-box;
    max-width: 414px;
    height: auto;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--backgroundColor-second);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06), 0px 0px 24px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.04);
}

.modalThemeComponent {
    width: 100%;
    gap: 16px;
    box-sizing: border-box;
    max-width: 414px;
    height: auto;
    max-height: 680px;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--backgroundColor-second);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06), 0px 0px 24px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.04);
}

.modalThemeOuterContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}

.modalLogoutOuterContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
}

.modalThemeSelectContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: row;
    width: 100%;
}

.modalMarketTimeFrameInnerContainer {
    border-radius: 12px;
    background-color: var(--backgroundColor-one);
    gap: 8px;
    padding: 8px 0;
    box-sizing: border-box;
}

.modalThemeInnerContainer {
    border-radius: 12px;
    background-color: var(--backgroundColor-one);
    gap: 8px;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 24px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.modalMarketTimeFrameInnerItem {
    box-sizing: border-box;
    padding: 0 24px;
    display: flex;
    height: 56px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modalMarketTimeFrameInnerText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0;
}

.modalWalletGlobalAssetComponent {
    gap: 16px;
    box-sizing: border-box;
    max-width: 414px;
    width: 100%;
    height: auto;
    max-height: 608px;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--backgroundColor-one);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06), 0px 0px 24px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.04);
}

.modalSaveAddressComponent {
    max-height: 100% !important;
}

.statementBottomText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    color: var(--text-grey);
}

.confirmFullWidthButton {
    width: 100%;
}

.pasteButtonContainer {
    padding: 0 24px 16px 24px;
    box-sizing: border-box;
    position: absolute;
    max-width: 414px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
}

.depositSubtitleText {
    width: 100%;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
}

.bigDepositIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 0;
}

.depositEWalletCopyContainer {
    width: 100%;
    height: 56px;
    background-color: var(--text-one);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    padding: 6px 16px;
    border-radius: 12px;
}

.depositEWalletCopyContainerLoading {
    width: 100%;
    height: 56px;
    background-color: var(--text-one);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    box-sizing: border-box;
    padding: 6px 16px;
    border-radius: 12px;
    animation: loading 1s infinite;
}

.depositEWalletCopyText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: var(--backgroundColor-one);
    margin: 0;
}

.depositEWalletLinkContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
}

.depositEWalletSeparateContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-direction: row;
}

.depositEWalletSeparator {
    width: 100%;
    background-color: var(--backgroundColor-third);
    height: 1px;
}

.depositEWalletSeparateText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--text-grey);
    margin: 0;
}

.depositInnerHeaderContainerText {
    width: 100%;
    text-align: left;
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
}

.depositInnerContainerList {
    width: 100%;
    gap: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.depositInnerContainerListText {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: var(--text-one);
    text-align: left;
}

.modalSelectDepositAssetSearchInput {
    height: 42px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    width: 100%;
    padding-bottom: 16px;
}

.depositAssetSelectScrollContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: scroll;
    position: relative;
}

.modalAssetDepositSelectContent {
    overflow-y: scroll;
}

.depositFeeText {
    width: 100%;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--text-grey);
    padding: 0 16px;
    box-sizing: border-box;
    text-align: left;
}

.depositAmountInputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.currencyAssetIcon {
    width: 18px;
    height: 18px;
    padding-top: 13px;
    padding-left: 13px;
}

.withdrawButtonsBottomSeparator {
    width: 100%;
    height: 1px;
    background-color: var(--backgroundColor-second);
}

.withdrawCryptoButtonsBottomInnerContainer {
    padding: 0 24px 16px 24px;
    box-sizing: border-box;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    gap: 16px;
    display: flex;
    flex-direction: row;
    width: 100%;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 414px;
}

.withdrawCryptoButtonsBottomContainer {
    z-index: 3;
    background-color: var(--backgroundColor-one);
    height: 101px;
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    bottom: 0;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.savedAddressesButtonsBottomContainer {
    z-index: 3;
    background-color: var(--backgroundColor-second);
    height: 72px;
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    bottom: 0;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.withdrawCryptoBottomAmountContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.withdrawCryptoBottomButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.withdrawCryptoBottomAmountContainerHeader {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--text-grey);
    margin: 0;
    text-align: left;
}

.withdrawCryptoBottomAmountContainerMainSmallerText {
    font-size: 14px !important;
}

.withdrawCryptoBottomAmountContainerMain {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: var(--text-one);
    text-align: left;
    margin: 0;
}

.withdrawCryptoBottomAmountContainerBottom {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--text-grey);
    margin: 0;
    text-align: left;
}

.depositButtonsBottomContainer {
    padding: 0 24px 16px 24px;
    box-sizing: border-box;
    position: fixed;
    max-width: 414px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.profileVerificationContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
}

.copyGreenIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.copyGreenText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: var(--text-color-link);
    margin: 0;
}

.copyGreenContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}

.profileVerificationInnerText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--text-color-link);
    margin: 0 0 0 16px;
    text-align: left;
    width: 100%;
}

.modalGenerateStatement {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 16px;
}

.modalComponentGrey {
    gap: 16px;
    box-sizing: border-box;
    max-width: 414px;
    width: 100%;
    height: auto;
    max-height: 608px;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--backgroundColor-second);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06), 0px 0px 24px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.04);
}

.modalOperationDetailsComponentGrey {
    gap: 16px;
    box-sizing: border-box;
    max-width: 414px;
    width: 100%;
    height: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--backgroundColor-second);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px 36px;
    box-shadow: 0px 60px 120px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06), 0px 0px 24px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.04);
}

.walletTransferModalBottomContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 16px;
    height: 56px;
}

.walletConvertModalBottomContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 16px;
    height: 56px;
}

.walletConvertModalReceivedContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.walletConvertModalReceivedContainerHeader {
    margin: 0;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--text-grey);
}

.walletConvertModalReceivedContainerBottom {
    margin: 0;
    text-align: left;
    font-style: normal;
    color: var(--text-one);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
}

.modalWalletEmptyText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0;
}

.secretKeyQrContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 100%;
    padding: 16px 24px;
    background: var(--backgroundColor-second);
    border-radius: 12px;
}

.secretKeyText {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: var(--text-grey);
}

.secretKeyInnerText {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: var(--text-one);
}

.secretKeyInnerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.hideBalanceLinkText {
    margin: 0;
    color: var(--text-color-link);
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.hideLowBalanceIcon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.themeCheckboxIcon {
    width: 24px;
    height: 24px;
}

.assetItem {
    max-width: var(--width-desktop);
    height: 44px;
    padding: 14px 0 14px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 16px;
}

.walletConvertModalItem {
    margin-left: 104px !important;
}

.innerAssetItemHeader {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    position: absolute;
    margin-left: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
}

.innerAssetHeaderText {
    margin: 0;
    color: var(--text-one);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

.innerAssetBottomText {
    margin: 0;
    color: var(--text-grey);
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.innerAssetItemAmount {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
}

.innerAssetItemAmountText {
    margin: 0;
    color: var(--text-one);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
}

.innerAssetItemAmountUSDText {
    margin: 0;
    color: var(--text-grey);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    display: flex;
    flex-direction: row;
    gap: 2px;
}

.assetIcon {
    width: 44px;
    height: 44px;
}

.exchangeInput {
    outline: none;
    box-shadow: none;
    border: 0;
    width: calc(100% - 115px);
    background-color: var(--backgroundColor-second);
    color: var(--text-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.networkItemContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 72px;
    box-sizing: border-box;
    padding: 12px 0;
}

.networkItemInnerTextContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}

.networkItemInnerTextHeader {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0;
    text-align: left;
}

.networkSelectIconContainer {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.networkItemInnerTextBottom {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
    text-align: left;
}

.exchangeInput-dark::placeholder {
    color: var(--text-grey);
    opacity: 0.4;
}

.exchangeInput::placeholder {
    color: var(--text-grey);
    opacity: 0.4;
}

.exchangeInputError {
    outline: none;
    box-shadow: none;
    border: 0;
    width: calc(100% - 115px);
    background-color: var(--backgroundColor-second);
    color: var(--text-color-error);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.exchangeInputContainerFocused {
    background: linear-gradient(var(--backgroundColor-second) 0, var(--backgroundColor-second)) padding-box,
        linear-gradient(90deg, var(--text-color-link) 0%, var(--text-color-link) 100%);
    border: 1px solid transparent;
}

.inputHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.inputHeaderFirstElement {
    color: var(--text-grey);
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.inputHeaderAmountContainer {
    display: flex;
    flex-direction: row;
}

.inputHeaderSecondElement {
    margin: 0 5px 0 0;
    color: var(--text-grey);
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.inputHeaderThirdElement {
    margin: 0;
    color: var(--text-grey);
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}

.inputAssetIcon {
    width: 28px;
    height: 28px;
}

.withdrawInputAssetIcon {
    width: 20px;
    height: 20px;
}

.exchangeChooseNothingText {
    width: 100%;
    text-align: left;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color-link);
    padding: 16px 0;
    margin: 0;
}

.inputAssetText {
    color: var(--text-one);
    margin: 0 0 0 8px;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.inputAssetTextNotSelected {
    color: var(--text-grey);
    margin: 0 0 0 8px;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.withdrawInputAssetText {
    font-family: Noto Sans, serif;
    margin: 0 0 0 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
}

.inputMain {
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inputMainAssetContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: max-content;
}

.inputBottomErrorText {
    color: var(--text-color-error);
    text-align: left;
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.bottomNavigationDivider {
    width: 100%;
    height: 1px;
    background: var(--backgroundColor-second);
}

.innerBottomNavigationContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.assetNavigationItem {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
    height: 32px;
    border-radius: 12px;
}

.biometryInnerContainer {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;
}

.biometryInnerHeaderText {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: var(--text-one);
    margin: 0;
}

.biometryInnerBottomText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--text-grey);
    margin: 0;
}

.assetNavigationItemText {
    user-select: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-one);
    margin: 0;
}

.assetNavigationItemActive {
    background: var(--backgroundColor-second);
}

.assetsNavigationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 24px;
    height: 48px;
    gap: 24px;
}

.historyNavigationContainer {
    width: 100%;
    max-width: 414px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 24px;
    height: 48px;
}

.historyItemNameContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    max-width: 180px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    z-index: 1;
}

.operationIconInnerStatus {
    position: absolute;
    margin-left: 30px;
    margin-top: 26px;
}

.scrolledAssetsNavigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    isolation: isolate;
    overflow-x: scroll;
}

.historyDateFilterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 48px;
    max-width: 72px;
    height: 32px;
    background: var(--backgroundColor-second);
    border-radius: 12px;
}

.historyDateFilterContainerFilled {
    width: -webkit-fill-available;
    max-width: 150px;
}

.assetsDateFilterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 74px;
    height: 32px;
    background: var(--backgroundColor-second);
    border-radius: 12px;
}

.historyDateFilter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 48px;
}

.historyDateFilterFilled {
    width: auto !important;
}

.assetsDateFilter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    gap: 8px;
    width: 74px;
    height: 32px;
}

.assetsDateMarginFilterText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 0 8px;
    color: var(--text-one);
}

.assetsDateFilterText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: var(--text-one);
}

.upperAssetListItem {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--text-grey);
    margin: 0;
}

.innerAssetListContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.marketAssetItem {
    gap: 16px;
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.bankSavedAddressItem {
    gap: 16px;
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bankSavedAddressItemNameContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.marketAssetItemNameContainer {
    gap: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    z-index: 1;
    position: absolute;
    margin-left: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
}

.marketAssetItemRateContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}

.bankSavedAddressItemEditContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
}

.rateChevContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.positivePercent {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    text-align: right;
    color: var(--text-color-link);
}

.negativePercent {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: right;
    color: var(--text-color-error);
    margin: 0;
}

.upperMarketItemText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0;
}

.bottomMarketItemText {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
}

.startButton {
    margin-top: 24px;
    height: 56px;
    width: 100%;
}

.maintainHeaderText {
    margin: 0 0 16px 0;
    color: var(--text-one);
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: center;

}

.maintainHeaderBody {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0;
    color: var(--text-grey);
}

.operationIcon {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.commonOperationDetailsContainer {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    background-color: var(--backgroundColor-one);
    border-radius: 12px;
    flex-direction: column;
    gap: 16px;
    padding: 8px 0;
}

.keyOperationDetailsText {
    width: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
    text-align: left;
}

.valueOperationDetailsText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0 0 0 16px;
    text-align: right;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    word-break: break-all;
}

.operationDetailsIcon {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.invoiceDetailsCommonContainer {
    width: 100%;
    background-color: var(--backgroundColor-one);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    box-sizing: border-box;
}

.invoiceDetailsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    flex-direction: column;
    padding: 16px 24px;
    box-sizing: border-box;
}

.invoiceDetailsHeaderText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
    text-align: left;
    width: 100%;
}

.invoiceDetailsInnerContainer {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.invoiceDetailsBottomText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0;
    text-align: left;
    width: 100%;
}

.bottomInvoicePreviewContainer {
    height: 76px;
    background-color: var(--backgroundColor-third);
    border-radius: 0 0 12px 12px;
}

.bottomInvoicePreviewInnerContainer {
    box-sizing: border-box;
    height: 100%;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.invoicePreviewFileNameText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0;
    width: 100%;
    text-align: left;
}

.invoicePreviewContainer {
    width: 100%;
    background-color: var(--backgroundColor-second);
    border-radius: 12px;
}

.cardWarningText {
    width: 100%;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: var(--text-grey);
}

.cardWarningOuterContainer {
    width: 100%;
    box-shadow: 0px 24px 48px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06), 0px 0px 24px rgba(var(--shadow-card-container-first), var(--shadow-card-container-second), var(--shadow-card-container-third), 0.06);
    border-radius: 12px;
    height: 80px;
    margin-bottom: 80px;
}

.withdrawSavedAdressInputIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.includeFeeText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-one);
    width: 100%;
    text-align: left;
    margin: 0;
}

.includeFeeTextError {
    color: var(--text-color-error);
}

.includeFeeContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    width: 100%;
    height: 40px;
    gap: 10px;
}

.inputMaxButtonText {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--text-one);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cryptoWithdrawBottomWarningText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--text-grey);
    width: 100%;
    margin: 0;
}

.cryptoWithdrawCommonItemLeftText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0;
    text-align: left;
    width: 100%;
}

.cryptoWithdrawCommonItemLeftTextAddress {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
    margin: 0 20px 0 0;
    text-align: left;
    width: auto;
}

.cryptoWithdrawCommonItemRightText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0;
    text-align: right;
    width: 100%;
}

.cryptoWithdrawAddressErrorText {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--text-color-error);
    margin: 0;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
}

.cardWarningContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 16px;
    background: var(--backgroundColor-one);
    border-radius: 16px;
    box-sizing: border-box;
    align-items: center;
}

.invoicePreviewContainerLoading {
    height: 260px;
    width: 100%;
    background-color: var(--backgroundColor-second);
    border-radius: 12px;
    animation: loading 1s infinite;
}

.headerInvoicePreviewContainer {
    padding: 24px 24px 0 24px;
}

.commonOperationDetailsItem {
    flex-direction: row;
    height: 24px;
    justify-content: space-between;
    padding: 0 24px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.userActivityItemHeader {
    gap: 16px;
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.userActivityStatusIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.commonUserActivityItem {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    height: 256px;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 24px;
}

.userActivityItem {
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.userActivityItemKeyText {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
}

.visitHistoryValueContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: row;
}

.userActivityHeaderText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0;
}

.registrationCheckboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.registrationCheckbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0px;
    gap: 16px;

    width: 100%;

    border-radius: 4px;
}

.registrationCheckboxText {
    text-align: left;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
}

.registrationCheckboxTextError {
    text-align: left
    ;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-color-error);
}

.registrationPasswordRequirements {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--text-grey);
    text-align: left;
}

.registrationPasswordRequirementsError {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--text-color-error);
    text-align: left;
}

.userActivityItemValueText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-one);
    margin: 0;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a {
    font-family: var(--font-family);
}

::marker {
    color: var(--text-grey);
}

::-webkit-scrollbar:hover {
    display: none;
    background-color: var(--backgroundColor-one) !important;
}

::-webkit-scrollbar {
    display: none;
    border-radius: 5px !important;
    border: 1px solid transparent !important;
    background-color: var(--backgroundColor-one) !important;
    background-clip: content-box !important;
    width: 10px !important;
}

p {
    margin: 0;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.478431) !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    border: 1px solid transparent !important;
    background-color: rgba(255, 255, 255, 0.32549) !important;
    background-clip: content-box !important;
}